.App {
  text-align: center;
  display: flex; 
  #flex-direction: column;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
body {
  width: 100vw;
  overflow-x: hidden;
  background-image: url("img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
.App-body {
  display: flex;
  width: 100%;
  max-width: 100vw;
  flex-direction: column;
  #justify-content: space-between;
  #align-items: space-between;
}

.App-footer {
  background-color: #282c34;
}

.Code-block {
  max-width: 50%;
  overflow: hidden;
  max-height: 500px;
  font-size: 8px;
  text-align: left;
  background-color: black;
  color: white
}
.Img-block {
  width: 300px;
  height: 300px;
  align-self: flex-end;
  transform: translateX(500px);
  animation: 3s 1 normal rightIn;
  #animation-delay: 5s;
  animation-fill-mode: forwards;
}
.Img-block img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 64px 8px white;
  border-radius: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes rightIn {
  0% {
      transform: translateX(500px);
  }
  100% {
      transform: translateX(0px);
  }
}


